<template>
  <base-widget>
    <span slot="header">Montanari Dictionary</span>
    <div slot="body" class="dictionary-entries-widget montanari">
      <div v-if="! isAuthorized" class="body">
          <a class="btn btn-light border" href="https://brill.com/display/db/bdgo" target="_blank">
            Purchase Options
          </a>
      </div>
      <div v-if="isAuthorized" class="header">
        <div class="form-group">
          <div class="input-group">
            <input type="text" v-model="lemma" placeholder="lookup lemma" class="form-control"/>
          </div>
        </div>
      </div>
      <LoaderBall v-if="isAuthorized && $apollo.queries.entries.loading" />
      <div v-else class="body">
        <div v-if="isAuthorized && lemma && lemma.length >= 1" v-for="entry in entries" :key="entry.id">
          <div class="headword">{{ entry.headword }}</div>
          <span v-html="entry.data.content"></span>
        </div>
      </div>
      <span class="dictionary-footer">
        Entries from
        <a class="product-link" target="_blank" href="https://brill.com/display/db/bdgo">The Brill Dictionary of Ancient Greek</a>
      </span>
    </div>
  </base-widget>
</template>

<script>
import gql from 'graphql-tag';
import BaseWidget from "@/js/components/BaseWidget.vue";
import api from "@/js/api";
import {MontanariSearch} from "@/js/tracking";
const debounce = require('lodash.debounce');

export default {
  name: 'WidgetMontanari',
  components: {BaseWidget},
  data() {
    return {
      'lemma': '',
      'isAuthorized' : true
    };
  },
  watch: {
    lemma() {
      this.checkAuth();
      this.llCountSearch();
    } ,
  },
  methods: {
    async checkAuth() {
      this.isAuthorized = true;
      const params = {
        product: 'BDGO'
      }
      await api.checkAuth(params, result => {
        this.isAuthorized = result.access;
      });
    },
    llCountSearch: debounce(
      function f() {
        if( this.lemma !== '') {
          this.$counter.track('Search', MontanariSearch());
        }
      },
      500,
    ),
  },
  mounted() {
    this.checkAuth()
  },
  apollo: {
    entries: {
      query: gql`
        query DictionaryEntries($lemma: String!, $normalizeLemmas: Boolean!) {
          dictionaryEntries(
            lemma: $lemma
            normalizeLemmas: $normalizeLemmas
            dictionary_Urn: "urn:cite2:scaife-viewer:dictionaries.v1:montanari"
          ) {
            edges {
              node {
                id
                urn
                headword
                data
              }
            }
          }
        }
      `,
      variables() {
        return {
          lemma: `${this.lemma}`,
          normalizeLemmas: true,
        };
      },
      update(data) {
        return data.dictionaryEntries.edges.map(e => e.node);
      },
      skip() {
        const condition = this.lemma ? this.lemma.length < 1 : true;
        return condition;
      },
    }
  },
};
</script>

<style lang="scss">
  .montanari {
    font-family: 'Brill';
    font-size: 1.1em;

    .headword {
      font-size: 22px;
      font-weight: bold;
      margin-top: 0.5em;
      margin-bottom: 0.2em;
    }
  }

</style>
