<template>
  <base-widget>
    <span slot="header">Montanari Dictionary</span>
    <div slot="body" class="dictionary-entries-widget montanari">
      <div class="body">
          <a class="btn btn-light border" href="https://brill.com/display/db/bdgo" target="_blank">
            Purchase Options
          </a>
      </div>
      <span class="dictionary-footer"></span>
    </div>
  </base-widget>
</template>

<script>
import BaseWidget from "@/js/components/BaseWidget.vue";

// TODO: Count search in LL.
export default {
  name: 'WidgetMontanariNoAccess',
  components: {BaseWidget},
};
</script>

<style lang="scss">
  .montanari {
    font-family: 'Brill';
    font-size: 1.1em;

    .headword {
      font-size: 22px;
      font-weight: bold;
      margin-top: 0.5em;
      margin-bottom: 0.2em;
    }
  }

</style>
