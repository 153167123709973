const LENS_COARSE = 'coarse';
const LENS_FINE = 'fine';

const SORT_TITLE = 'title';
const SORT_RELEVANCE = 'relevance';

const SGGO_SCHEMA = {
  defaultLens: LENS_COARSE,
  sortFields: {
    default: SORT_TITLE,
    fields: {
      [SORT_RELEVANCE]: {
        label: 'Relevance',
        value: ['_score', 'urn'],
        keywordOnly: true,
      },
      [SORT_TITLE]: {
        label: 'Entry',
        value: ['sggo_work_label.keyword'],
      },
    },
  },
  sortParamsFunc: (($vm) => {
    if ($vm.sort === SORT_RELEVANCE) {
      return ['_score', 'urn'];
    }
    if ($vm.sort === SORT_TITLE) {
      return ['sggo_work_label.keyword'];
    }
    return [];
  }),
  keywordWatcherFunc: (($vm, newValue, oldValue) => {
    if (!oldValue && !$vm.userHasSorted) {
      $vm.sort = SORT_RELEVANCE;
    } else if (!newValue && $vm.sort === SORT_RELEVANCE) {
      $vm.sort = SORT_TITLE;
      $vm.userHasSorted = false;
    }
  }),
  coarseResultKeyFunc: (result => result.sggo_entry_urn[0]),
  queries: {
    work_labels: '',
    version_kinds: '',
    index_provenances: '',
    index_historians_dates: '',
    index_ancient_author_titles: '',
    index_ancient_titles: '',
    index_subjects: '',
    index_languages: '',
  },
  lookups: {
    work_labels: [],
    version_kinds: [],
    index_provenances: [],
    index_historians_dates: [],
    index_ancient_author_titles: [],
    index_ancient_titles: [],
    index_subjects: [],
    index_languages: [],
  },
  filters: {
    work_labels: null,
    version_kinds: null,
    index_provenances: null,
    index_historians_dates: null,
    index_ancient_author_titles: null,
    index_ancient_titles: null,
    index_subjects: null,
    index_languages: null,
  },
  facets: [
    {
      label: 'Grammarian',
      value: 'work_labels',
      toolTipText: 'Filter by grammarian',
      placeholder: '',
    },
    {
      label: 'Provenance',
      value: 'index_provenances',
      toolTipText: 'Filter by provenance',
      placeholder: '',
    },
    {
      label: 'Date',
      value: 'index_historians_dates',
      toolTipText: 'Filter by date',
      placeholder: '',
    },
    {
      label: 'Source Author',
      value: 'index_ancient_author_titles',
      toolTipText: 'Filter by source author',
      placeholder: '',
    },
    {
      label: 'Source Work',
      value: 'index_ancient_titles',
      toolTipText: 'Filter by source work',
      placeholder: '',
    },
    {
      label: 'Subject',
      value: 'index_subjects',
      toolTipText: 'Filter by subject',
      placeholder: '',
    },
    {
      label: 'Version',
      value: 'version_kinds',
      flatten: true,
      multiple: true,
      toolTipText: 'Filter by version',
      placeholder: '',
    },
    {
      label: 'Language',
      value: 'index_languages',
      toolTipText: 'Filter by language',
      placeholder: '',
    },
  ],
  lenses: {
    coarse: {
      label: 'entry',
      pluralLabel: 'entries',
      kind: LENS_COARSE,
    },
    fine: {
      label: 'passage',
      pluralLabel: 'passages',
      kind: LENS_FINE,
    },
  },
};

export default SGGO_SCHEMA;
